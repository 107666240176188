import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import forOwn from 'lodash/forOwn';

@Component({
    standalone: false,
    selector: 'hp-hcp-input-text',
    templateUrl: './input-text.html',
    styleUrls: ['./input-text.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputTextComponent),
        multi: true
    }]
})
export class InputTextComponent implements ControlValueAccessor, OnInit {

    @ViewChild('inputBox') public inputBox: ElementRef;

    @Input() public categoryId = 'text_field';
    @Input() public domId = '';
    @Input() public errorMessage = '';
    @Input() public hasError = false;
    @Input() public nativeAttributes?: object = {};
    @Input() public placeholder: string;
    @Input() public type?: 'text' | 'number' = 'text';

    public disabled = false;
    public value: string | number;
    public onChange = (value) => undefined;

    public ngOnInit() {
        this._setInputDynamicAttributes();
    }

    public writeValue(value: any) {
        this.value = value;
    }

    public registerOnChange(onChangeCallback: any) {
        this.onChange = onChangeCallback;
    }

    /* tslint:disable-next-line:no-empty */
    public registerOnTouched() { }

    public keyDown(event) {
        if (event.key === 'Tab') {
            return;
        }
        return this.type === 'number'
            ? this._acceptNumericValuesOnly(event)
            : undefined;
    }

    public setDisabledState (isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private _acceptNumericValuesOnly(keyDownEvent) {
        if (keyDownEvent.metaKey || keyDownEvent.shiftKey) {
            keyDownEvent.preventDefault();
            return;
        }

        const { keyCode } = keyDownEvent;
        const removeKeyCodes = Object.values({
            backspace: 8,
            delete: 46
        });

        const isDeleteCharKey = removeKeyCodes.indexOf(keyCode) !== -1;
        const isArrowKey = keyCode >= 35 && keyCode <= 39;
        if (isDeleteCharKey || isArrowKey) {
            return;
        }

        const numberRegex = /[\d]/;
        let inputChar = String.fromCharCode(keyCode);
        if (!numberRegex.test(inputChar)) {
            keyDownEvent.preventDefault();
        }
    }

    private _setInputDynamicAttributes() {
        forOwn(
            this.nativeAttributes,
            (value, key) => this.inputBox.nativeElement.setAttribute(key, value)
        );
    }

}
