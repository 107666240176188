/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Component, OnInit, HostListener, Input } from '@angular/core';
import {  Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HpHcpAuth } from '../../service/hp-hcp-auth.service';
import { HpHcpMultOrgService } from '../../service/hp-hcp-multi-org.service';
import { HpHcpRoutesEmptyOrganizationGuard } from '../../service/hp-hcp-routes-guard.service';
import { HpHcpUrlRedirect } from '../../service/hp-hcp-url-redirect.service';
import { HpHcpUserConfigService, PORTAL_MODALITY_IDENTIFIER_MANUAL } from '../../service/hp-hcp-user-config.service';
import { HpHcpUserSession } from '../../service/hp-hcp-user-session.service';
import { OrganizationModel } from '../../model/organization.model';
import { UserModel } from '../../model/user.model';
import { ExpiredSubscriptionModalComponent } from './subscription-expired-modal/subscription-expired-modal';
import { WarningDataLossModalComponent } from './warning-data-loss-modal/warning-data-loss-modal.component';
import { DateUtility } from '../../utils/date-utility';
import { HpHcpLocalStorage } from '../../service/hp-hcp-localstorage.service';
import { AppConstants } from '../../../config/constants';
import { TranslateService } from '@ngx-translate/core';
import { TkrExternalSsoService } from '../../service/tkr-ds-sso-navigation.service';
import { environment } from '../../../../environments/environment';
import { PatientModel } from '../../model/patient.model';
import { DocsperaBasicfeature, DocsperaSmartScheduler } from '../../constants/product-types';
import { SiteModel } from '../../model/site.model';
import { PatientListService } from '../../../dashboard/service/patient-list.service';
import { HelperUtility } from '../../utils/helper-utility';

/*
 * @author      @version    @date           @description
 * JVelagal     01          Feb 21, 2023    AFLL-13312 | Export PROMs data | Navigate to the 'Create Reports' page
 * JVelagal     02          Feb 28, 2023    AFLL-13312 | Fix the Enable/disable header menu Logic reports tab showing
 * RParamma     03          Mar 15, 2023    AFLL-13312 | Fix Reports menu not showing for dvh south site
 * HSenevir     04          Apr 01, 2023    AFLL-16880 - Supporting Angular CLI build and refactoring
 * EElangav     05          Apr 10, 2023    AFLL-15217 Report Including Total VRAS Patients Count
 * HSenevir     06          Apr 10, 2023    AFLL-16880 - Fixing menu issues after Angular upgrade
 * EElangav     07          Apr 11, 2023    AFLL-15217 - Added Total VRAS Patients Count
 * EElangav     08          Apr 12, 2023    AFLL-15217 - Fixed VRAS patient count dropdown css.
 * ZDaudy       9           May 25, 2023    AFLL-17602 - RTM | Decouple the Global Navigation and sub-navigation for 'Reports'
 * RPenimos     10          Feb 08, 2024    AFLL-20731 - ANZ | Update the translation file logic to support ANZ Launch
 * RPenimos     11          Feb 15, 2024    AFLL-20711 - ANZ | Localize Privacy Policy copy for a HCP user
 * PPareek      12          Sep 23, 2024    AFLL-22137 - Changes done so that test cases dont fail in jenkins build
 */

@Component({
    selector: 'tkr-header',
    templateUrl: './header.html',
    styleUrls: ['./header.scss'],
    host: {
        '(document:click)': 'closeMenuOnFocusOut($event)'
    }
})
export class HeaderComponent implements OnInit {

    @Input() public displayWarningDataLossModal = false;
    public isDashboard = false;
    public isReport = false;
    public isManualPortal = false;
    public isOrganizationalAdmin = false;
    public canLoadPage: boolean;
    public selectedOrganization: OrganizationModel;
    public selectedLang = '';
    public showLanguageMenu = false;
    public showDropdownMenu = false;
    public showDropdownMenuDS = false;
    public organizations: Array<OrganizationModel> = [];
    public orgSelectionPermitted = false;
    public isSubscriptionExpired: boolean;
    public subscriptionExpireDate = '';
    public username = 'My Profile';
    public isDocsperaSite = false;
    public isSmartSchedulerSite = false;
    public isSmartSchedulerUser = false;
    public isAdmin: boolean;
    private userSession: UserModel;
    public patient: PatientModel;
    public isShowReport: boolean;
    public isVrasSite: boolean;
    public vrasPatientCount: number;

    constructor(
        public urlRedirectService: HpHcpUrlRedirect,
        private authService: HpHcpAuth,
        private configService: HpHcpUserConfigService,
        private externalSsoService: TkrExternalSsoService,
        private guard: HpHcpRoutesEmptyOrganizationGuard,
        private multiOrgService: HpHcpMultOrgService,
        private router: Router,
        private userSessionService: HpHcpUserSession,
        private localStorage: HpHcpLocalStorage,
        public dialog: MatDialog,
        public translateService: TranslateService,
        public _patientListService: PatientListService,
        private _helperUtility: HelperUtility
    ) {
        this.userSession = this.userSessionService.getUserSession();
        environment.country = this.userSession.organization.country ? this.userSession.organization.country : 'US';
        let lng = this.setLanguage(environment.country);
        this.translateService.setDefaultLang(lng);
        this.translateService.use(lng);
        DateUtility.setDateFormats();
        this.selectedOrganization = this.userSession.organization;
        this.canLoadPage = this.guard.canLoadNonOrganizationPage();
        this.orgSelectionPermitted = this.authService.orgSelectionPermitted();
        this.selectedLang = this.translateService.currentLang;
        const featureList = this.userSession.sites[0].productDetails.features;
        this.isDocsperaSite = featureList.indexOf(DocsperaBasicfeature) !== -1;
        this.isSmartSchedulerSite = featureList.indexOf(DocsperaSmartScheduler) !== -1;
        this.isAdmin = this.userSession.roles[0].machineName == 'ROLE_ORG_ADMIN';
        this.isShowReport = (this._helperUtility.isPromsEnabled() || this._helperUtility.isRTMEnabled());
        
        
    }

    public _isManualProtal(carePlan) {
        if (!(carePlan && carePlan.addPatient)) {
            return false;
        }
        this.isManualPortal = carePlan.addPatient === PORTAL_MODALITY_IDENTIFIER_MANUAL;
    }

    public ngOnInit() {
        this.vrasPatientCount = this._patientListService.getVrasPatientCount();
        this.isSmartSchedulerUser = this.userSession.smartScheduling;
        this.configService.getCarePlan(carePlan => this._isManualProtal(carePlan));
        this.isOrganizationalAdmin = this.userSessionService.isOrganizationalAdmin();
        this.organizations = this.userSessionService.getOrganizations();
        this.subscriptionExpireDate = JSON.parse(sessionStorage.getItem('userSession')).organization.subscriptionExpireDate;
        this.isSubscriptionExpired = this.subscriptionExpireDate ?
            DateUtility.isDateSameAndBeforeToday(Date.parse(this.subscriptionExpireDate), true) : false;



        this.username = this.userSession.firstName + ' ' + this.userSession.lastName;
        this.isDashboard = this.urlRedirectService.compareBaseUrl(
            this.urlRedirectService.urls.dashboard,
            this.router.url
        );
        this.username = this.userSession.firstName + ' ' + this.userSession.lastName;
        this.isReport = this.urlRedirectService.compareBaseUrl(
            this.urlRedirectService.urls.viewReports, this.router.url
        );
    }

    public goToAddPatientView() {
        if (this.isSubscriptionExpired) {
            this.dialog.open(ExpiredSubscriptionModalComponent, {
                data: {
                    subscriptionExpireDate: this.subscriptionExpireDate
                }, disableClose: true
            });
        } else if (this.displayWarningDataLossModal) {
            this.dialog.open(WarningDataLossModalComponent, {
                panelClass: 'warning-data-loss-modal',
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.localStorage.removeLocalStorageItem('isRegistrationInProgress');
                    this.router.navigateByUrl(this.urlRedirectService.urls.addPatient + '?newPatient=true');
                }
            });
        } else {
            this.router.navigateByUrl(this.urlRedirectService.urls.addPatient + '?newPatient=true');
        }
    }

    public goToDashboardView() {
        if (this.displayWarningDataLossModal) {
            this.dialog.open(WarningDataLossModalComponent, {
                panelClass: 'warning-data-loss-modal',
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.localStorage.removeLocalStorageItem('isRegistrationInProgress');
                    this.router.navigateByUrl(this.urlRedirectService.urls.dashboard);
                }
            });
        } else {
            this.router.navigateByUrl(this.urlRedirectService.urls.dashboard);
        }
    }
    public goToReportsView() {
        if (this.displayWarningDataLossModal) {
            this.dialog.open(WarningDataLossModalComponent, {
                panelClass: 'warning-data-loss-modal',
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.localStorage.removeLocalStorageItem('isRegistrationInProgress');
                    this.router.navigateByUrl(this.urlRedirectService.urls.createReports);
                }
            });
        } else {
            this.router.navigateByUrl(this.urlRedirectService.urls.createReports);
        }
    }
    public navigateToViewReports() {
        const viewReportsUrl = `${this.urlRedirectService.urls.viewReports}`;
        this.router.navigateByUrl(viewReportsUrl);
    }
    public goToMyProfile() {
        if (this.displayWarningDataLossModal) {
            this.dialog.open(WarningDataLossModalComponent, {
                panelClass: 'warning-data-loss-modal',
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.router.navigateByUrl(this.urlRedirectService.urls.hcpProfile);
                    this.localStorage.removeLocalStorageItem('isRegistrationInProgress');
                }
            });
        } else {
            this.router.navigateByUrl(this.urlRedirectService.urls.hcpProfile);
        }
    }

    public logout() {
        const redirectToLogin = () => {
            // window.location.href = `${AppConstants.vdsUrl}/#/login?${this.authService.getLogoutParams()}`;
            this.router.navigateByUrl(this.urlRedirectService.urls.login);
        };
        const onLogoutFailure = null;
        this.authService.logout(redirectToLogin, onLogoutFailure);
    }

    public toggleActiveMenu(e) {
        this.vrasPatientCount = this._patientListService.getVrasPatientCount();
        if (e && (e.target.id == 'user-options' || (e.target.parentElement && e.target.parentElement.id == 'user-options'))) {
            this.showDropdownMenu = !this.showDropdownMenu;
        }
    }

    public toggleLanguageMenu(e) {
        this.showLanguageMenu = !this.showLanguageMenu;
    }
    public toggleDSMenu(e) {
        if (e && (e.target.id == 'ds-more-options' || (e.target.parentElement && e.target.parentElement.id == 'ds-more-options'))) {
            this.showDropdownMenuDS = !this.showDropdownMenuDS;
        }
    }

    public switchOrganization(organization) {
        this.multiOrgService.switchOrganization(
            organization,
            () => this.router.navigateByUrl(this.urlRedirectService.urls.dashboard)
        );
    }

    public toggleLanguage(langCode: string) {
        this.selectedLang = langCode;
        this.showLanguageMenu = !this.showLanguageMenu;
        this.translateService.use(langCode);
    }
    public navigateToDocSpera(siteLocation) {
        this.externalSsoService.getSignedUrl(siteLocation, '', '',
            (res) => {
                window.open(res.url, 'DocSpera');
            }, (err) => {
            });
    }

    @HostListener('document:click', ['$event'])
    public documentClick(event) {
        let isProfileMenuFocusedOut = event.target.id != '' ? event.target.id !== 'user-options' :
            event.target.parentElement && event.target.parentElement.id !== 'user-options';
        let isCareCoordinationMoreMenuFocusedOut = event.target.id != '' ? event.target.id !== 'ds-more-options' :
            event.target.parentElement && event.target.parentElement.id !== 'ds-more-options';

        if (isProfileMenuFocusedOut && this.showDropdownMenu) {
            this.showDropdownMenu = false;
        }

        if (isCareCoordinationMoreMenuFocusedOut && this.showDropdownMenuDS) {
            this.showDropdownMenuDS = false;
        }
    }

    public setLanguage(country: string): string {
        let lng = null;
        switch (country) {
            case 'UK':
                lng = 'en-GB';
                break;
            case 'AU':
                lng = 'en-GB';
                break;
            case 'NZ':
                lng = 'en-GB';
                break;
            default:
                lng = 'en-US';
        }
        return lng;
    }
}
