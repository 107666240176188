import { Component, Input, OnInit } from '@angular/core';

import capitalize from 'lodash/capitalize';

@Component({
    standalone: false,
    selector: 'hp-hcp-progressbar-circular-title',
    templateUrl: './progressbar-circular-title.html',
    styleUrls: ['./progressbar-circular-title.scss']
})
export class ProgressbarCircularTitleComponent implements OnInit {

    @Input() public title: string;

    public ngOnInit() {
        this.title = this.title.split('-').map(capitalize).join('-');
    }

}
