 /**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule , NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppRoutingModule} from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { ServiceModule } from './shared/service.module';
import { ToastConfig} from './shared/constants/toast-config';
import { AppComponent } from './app.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Apr 01, 2023    AFLL-16880 - Supporting Angular CLI build and refactoring
 *  HSenevir    02          Jul 17, 2023    AFLL-18875 - Removed CookieService library
 *  PPareek     03          Dec 14, 2023    AFLL-19609 - Sonar | Removed duplicate Import.
 *  PPareek     04          Jul 18, 2024    AFLL-21762 | Changes done to upgrade to Angular v 18
 */

@NgModule({
    exports: [
        AppComponent
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        AuthModule,
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        SharedModule,
        ServiceModule.forRoot(),
        ToastrModule.forRoot(ToastConfig),
        TooltipModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (httpLoaderFactory),
                deps: [HttpClient]
            }
        })],
    providers: [
        Title,
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule { }

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
