import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: false,
    name: 'round'
})
export class RoundPipe implements PipeTransform {

    public transform(value: number): number {
        return Math.round(value);
    }

}
