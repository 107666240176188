<div class="hp-hcp-dismiss-comment" [class.right-side]="displayOnRightSide">
    <div class="dismiss-comment-wrapper">
        <div class="dismiss-comment-box">
            <div class="close-section">
                <a data-dom-id="notification_close_alert" class="fa fa-times close-icon link-icon" (click)="closeBox()"></a>
            </div>
            <div class="textarea-section">
                <textarea class="comment-field"
                          maxlength="140"
                          placeholder="Enter comment here..."
                          data-dom-id="notification_textbox"
                          aria-label="notification_textbox"
                          [ngModel]="comment"
                          (ngModelChange)="updateComment($event)">
                </textarea>
            </div>
            <div class="buttons-section">
                <button data-dom-id="notification_cancel_alert" class="button cancel-button" (click)="closeBox()">Cancel</button>
                <button class="button save-button"
                        data-dom-id="notification_dismiss_alert"
                        [disabled]="isDismissDisabled"
                        (click)="dismissWithComment()">
                    Dismiss Alert
                </button>
            </div>
        </div>
        <div class="comment-arrow"></div>
    </div>
    <div class="dismiss-covering" (click)="closeBox()"></div>
</div>
