import { Directive, Output, EventEmitter } from '@angular/core';

@Directive({
    standalone: false,
    selector: '[enter-key-click]',
    host: {
        '(document:keydown.enter)': 'onEnterKeyClick($event)'
    }
})
export class EnterKeyClickDirective {
    @Output() public onEnter: EventEmitter<any> = new EventEmitter();

    public onEnterKeyClick($event) {
        this.onEnter.emit($event);
    }
}
