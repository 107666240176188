/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ReportService } from './../../reports/services/report.service';
import { DatePattern } from '../constants/date-range-picker-patterns';

/*
 * @author      @version    @date           @description
 * JSumandi     1           Nov 24, 2023    AFLL-17265 PROMs| Added current year as default year as a behavior to the Date-Range-Picker
 * PPareek      2           Sep 18, 2024    AFLL-22137 - Changes done so that test cases dont fail in jenkins build
*/


@Directive({
  selector: '[appDateRangePicker]'
})
export class DateRangePickerDirective {

  private userInput: string = '';

  constructor(
    private ngControl: NgControl,
    private elmt: ElementRef<HTMLInputElement>,
    private reportService: ReportService
  ) { }

  @HostListener('focus', ['$event']) onFocus() {
    this.ngControl.control?.valueChanges.subscribe(() => {
      this.userInput = this.elmt.nativeElement.value;
    });
  }

  @HostListener('blur', ['$event']) onFocusOut() {
    if (this.userInput.length > 0 && this.userInput.length <= 10) {
      if (this.reportService.getRegExpression(DatePattern.VALID_DATE_PATTERN).test(this.userInput)) {
        this.ngControl.control?.setValue(new Date(this.userInput));
        this.userInput = '';
        
      } else {
        if (this.reportService.getRegExpression(DatePattern.VALID_DATE_MONTH_PATTERN).test(this.userInput)) {
          this.ngControl.control?.setValue(new Date(`${this.userInput}` + '/1/' + `${new Date().getFullYear()}`));

        } else if (this.reportService.getRegExpression(DatePattern.VALID_DATE_MONTH_DAY_PATTERN).test(this.userInput)) {
          this.ngControl.control?.setValue(new Date(`${this.userInput}` + '/' + `${new Date().getFullYear()}`));

        } else if (parseInt(this.userInput) === 0) {
          this.ngControl.control?.setValue(new Date(new Date().getFullYear(), 0, 1));

        } else {
          let dateElements = this.userInput.split('/', 3);
          this.ngControl.control?.setValue(new Date(`${dateElements[0]}` + '/' + `${dateElements[1]}` + '/' + `${new Date().getFullYear()}`));
        }
        this.userInput = this.elmt.nativeElement.value;
      }
    }
  }
}
