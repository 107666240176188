import { Component, Input } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-healthy-behaviors-info-icon',
    templateUrl: './healthy-behaviors-info-icon.html'
})
export class HealthyBehaviorsInfoIconComponent {
    @Input() public tooltipPlacement: 'top' | 'left' | 'bottom' | 'right' = 'top';
}
