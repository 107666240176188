import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
    standalone: false,
    selector: '[hasFloatingLabel]'
})
export class HasFloatingLabelClassDirective {

    @Input('hasFloatingLabel') public className: string;

    private _elementRef: ElementRef;
    private _renderer: Renderer2;

    constructor(elementRef: ElementRef, renderer: Renderer2) {
        this._elementRef = elementRef;
        this._renderer = renderer;
    }

    public ngAfterViewInit() {
        this._setClass();
    }

    @HostListener('input') public onEvent() {
        this._setClass();
    }

    private _setClass() {
        if (!this._elementRef.nativeElement.value) {
            this._renderer.addClass(this._elementRef.nativeElement, this.className);
        } else {
            this._renderer.removeClass(this._elementRef.nativeElement, this.className);
        }
    }

}
