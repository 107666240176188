<span (click)="closeLinkExpirationModal()" class="close-button"> &#x2716; </span>
<div>
    <div class="expired-link-modal-body">
        <div class="update-confirmation">
            <img src="../../../../assets/img/warning-icon.png" alt="warning"/>
            <h1 class="title">The link has expired!</h1>
            <div class="message">
                This password reset link has expired or already been used.
                <br />
                Please submit the <b>Reset Password</b> request again.
            </div>
        </div>
        <div class="form-buttons">
            <button type="button" class="button" (click)="closeLinkExpirationModal()">
                Close
            </button>
        </div>
    </div>
</div>
