// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tkr-footer-app {
  z-index: 2000;
  position: fixed;
  bottom: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/app.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;AACJ","sourcesContent":[".tkr-footer-app {\n    z-index: 2000;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
