import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type Option = {
    id: any;
    name: string;
    value?: any;
    selected?: boolean;
    disabled?: boolean;
};

export type SelectOptions = Option[];

@Component({
    standalone: false,
    selector: 'hp-hcp-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectComponent),
        multi: true
    }]
})
export class SelectComponent implements ControlValueAccessor {

    @Input() public disabledOptions: Option[] = null;
    @Input() public eventGroup: string;
    @Input() public fixedContent = false;
    @Input() public label = 'Select';
    @Input() public multiple = false;
    @Input() public open = false;
    @Input() public options: Option[] = [];
    @Input() public optionsNotFound = 'Options not found';
    @Input() public placeholder = 'Site';
    @Input() public withPrefixedIndexAsDomId;

    public fieldLabel = '';
    public value: Option | Option[];

    public ngOnInit(): void {
        this.defineFieldLabel();
    }

    public clearDisabledOptions () {
        this.options.forEach((option) => option.disabled = false);
    }

    public setDisabledOptions () {
        if (this.disabledOptions && this.disabledOptions.length) {
            this.options.forEach((option) =>
                option.disabled = this.disabledOptions.some((disabledOption) =>
                    disabledOption.id === option.id));
        }
    }

    public propagateChanges = (value) => null;

    public selectOption (option: Option) {
        if (option.disabled) {
            return;
        }

        this.options.forEach((item) => {
            item.selected = item.id === option.id ;
        });

        if (!this.multiple) {
            this.close();
        }
    }

    public toggleContent () {
        if (this.open) {
            this.close();
        } else {
            this.open = true;
        }
    }

    public onChkChange () {
        if (this.fixedContent) {
            this.propagateChanges(this.getValues());
        }
    }

    public close () {
        if (this.fixedContent || !this.open) {
            return;
        }

        this.open = false;
        this.defineFieldLabel();
        this.propagateChanges(this.getValues());
    }

    public getValues (): Option | Option[] {
        const selectValue = this.options.filter(option => option.selected);
        return this.multiple ? selectValue : selectValue[0];
    }

    public defineFieldLabel () {
        const currentValue = this.getValues();
        if (this.multiple && currentValue instanceof Array) {
            switch (currentValue.length) {
                case 0:
                    this.fieldLabel = this.placeholder;
                    break;
                case this.options.length:
                    this.fieldLabel = 'All Sites selected';
                    break;
                default:
                    this.fieldLabel = `Selected sites (${currentValue.length})`;
            }
        } else {
            this.fieldLabel = !currentValue ? this.placeholder : (<Option>currentValue).name;
        }

    }

    public initializeDefaultOptionsSelected () {
        if (this.multiple && this.value instanceof Array) {
            this.options.forEach((option) => {
                option.selected = (this.value as SelectOptions).some((item) => item.id === option.id);
            });
        } else if (this.value) {
            this.options.forEach((option) => {
                option.selected = (this.value as Option).id === option.id;
            });
        }
        this.clearDisabledOptions();
        this.setDisabledOptions();
        this.defineFieldLabel();
    }

    public writeValue(obj: Option[] | Option): void {
        this.value = obj;
        if (!this.value && this.multiple) {
            this.value = [];
        }
        this.initializeDefaultOptionsSelected();
    }
    public registerOnChange(fn: any): void {
        this.propagateChanges = fn;
    }
    public registerOnTouched(fn: any): void {
        //
    }
}
