import { Component, Input } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-empty-notification',
    templateUrl: './empty-notification.html',
    styleUrls: ['./empty-notification.scss']
})
export class EmptyNotificationComponent {
    @Input() public isDashboardStyle = true;
}
