<div class="hp-hcp-auth-app">
    <div class="password-reset-wizard" *ngIf="!passwordResetConfirm">

        <div class="velys-logo-row text-center">
            <img class="velys-logo" src="../../../assets/img/velys-logo-signin.png" alt="velys logo reset" />
        </div>

        <h1 class="title">
            Reset your password
        </h1>

        <p class="password-information">
            Enter your new password below. Your password must be at least 8 characters long, with one uppercase and one numeric or special character.<br/><br/>
            You can’t reuse your past 5 passwords or any password created in the past 365 days.<br>
            Your password can only be changed once per day.
        </p>

        <form [formGroup]="resetPasswordForm" class="password-reset-form">

            <div class="input-field-row">
                <label for="newPassword">New password</label>
                <input class="form-input" type="password" formControlName="password"
                    data-dom-id="reset_password_input_password" aria-label="reset_password_input_password" autocomplete="off"/>
            </div>

            <span *ngIf="submitted && errors.newPasswordError" class="error-message">
                {{ errors.newPasswordError }}
            </span>

            <div class="input-field-row">
                <label for="reEnterPassword">Re-enter new password</label>
                <input class="form-input" type="password" formControlName="confirmedPassword"
                    data-dom-id="reset_password_input_confirm_password" aria-label="reset_password_input_confirm_password" autocomplete="off">
            </div>

            <span *ngIf="submitted && errors.confirmPasswordError" class="error-message">
                {{ errors.confirmPasswordError }}
            </span>

            <div class="password-reset-button text-center">
                <button mat-raised-button color="primary" class="submit-button" (click)="resetPassword()" [disabled]="submittedAndAllowed">Submit</button>
            </div>

            <a class="cancel-link" routerLink="/login" routerLinkActive="active">Cancel</a>

        </form>
    </div>

    <div class="password-reset-success-wizard" *ngIf="passwordResetConfirm">
        <div class="velys-logo-row text-center">
            <img class="velys-logo" src="../../../assets/img/velys-logo-signin.png" alt="velys logo reset" />
        </div>

        <h1 class="title">
            Password Reset Successful!
        </h1>

        <p class="password-reset-success-message">
            Your password has been successfully reset.<br/>
            You can now log in with your new password.<br />
        </p>

        <div class="success-return-button-container">
            <button type="button" class="button success-return-button" (click)="goToLogin()">
                Return to login
            </button>
        </div>
    </div>
</div>
