// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  cursor: pointer;
  line-height: 1.5rem;
}

.custom-input + label::before {
  font-size: 75%;
}

.disabled {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/ui/checkbox/checkbox.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":["label {\n  cursor: pointer;\n  line-height: 1.5rem;\n}\n\n.custom-input + label::before {\n  font-size: 75%;\n}\n\n.disabled {\n  opacity: .5;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
