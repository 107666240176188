import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { HpHcpAuth, RECENTLY_USED_STATUS_CODE, PASSWORD_STRENGTH_CRITERIA_ERROR,
    PASSWORDS_MATCH_ERROR,
    RECENTLY_CHANGED_STATUS_CODE} from '../../../shared/service/hp-hcp-auth.service';
import { HpHcpValidators } from '../../../shared/service/hp-hcp-validators.service';

const ERROR_MESSAGE = 'The application has encountered an unknown error.';
const ROUTE_LOGIN = 'login';

@Component({
    standalone: false,
    selector: 'hp-hcp-activation-activation-accept',
    templateUrl: './account-activation-accept.html',
    styleUrls: ['../account-activation.scss']
})
export class AccountActivationAcceptComponent implements OnInit {
    public errors = {
        newPasswordError: undefined,
        confirmPasswordError: undefined
    };

    public form: FormGroup;
    public isSubmitting = true;
    public responseError: string;
    public submitted: boolean;

    constructor(
        private _authService: HpHcpAuth,
        private _formBuilder: FormBuilder,
        private _hpHcpValidators: HpHcpValidators,
        private _routeParams: ActivatedRoute,
        private _router: Router
    ) {
        this.form = this._formBuilder.group({
            confirmedPassword: [''],
            invitationCode: [''],
            password: ['']
        });
    }

    public ngOnInit() {
        this._routeParams.queryParams.subscribe(({ invitationCode = '' }) =>
            this.verifyInvitationCode(invitationCode)
        );
    }

    public verifyInvitationCode(invitationCode) {
        const handleSuccess = () => {
            this.form.patchValue({ invitationCode });
            this.isSubmitting = false;
        };

        const handleError = ({ message = ERROR_MESSAGE }) =>
            (this.responseError = message);

        this._authService
            .verifyStaffInvitationCode({ invitationCode })
            .subscribe(handleSuccess, handleError);
    }

    public clearInputErrors () {
        this.errors.newPasswordError = undefined;
        this.errors.confirmPasswordError = undefined;
    }

    get shouldShowError() {
        return Object.keys(this.errors).some(error => this.errors[error]);
    }

    public goToLogin(event) {
        event.preventDefault();
        this._router.navigate([ROUTE_LOGIN]);
    }

    public activateUserAccount(event) {
        event.preventDefault();
        this.clearInputErrors();
        const { confirmedPassword, invitationCode, password } = this.form.value;
        const isPasswordValid = this._isPasswordValid(password);
        const isConfirmedPasswordValid = this._isConfirmedPasswordValid(password, confirmedPassword);

        if (!(isPasswordValid && isConfirmedPasswordValid)) {
            return;
        }

        this.isSubmitting = true;

        const handleComplete = () => this.isSubmitting = false;
        const handleSuccess = () => this.submitted = true;

        this._authService.activateStaffAccountByInvitation({ password, invitationCode })
            .subscribe(handleSuccess, (error) => this._handleActivateError(error), handleComplete);
    }

    private _handleActivateError({code , message = ERROR_MESSAGE}) {
        switch (code) {
            case RECENTLY_USED_STATUS_CODE:
            case RECENTLY_CHANGED_STATUS_CODE:
                this.errors.newPasswordError = message;
                return;
        }

        this.submitted = true;
        this.responseError = message;
    }

    private _isPasswordValid(password: string): boolean {
        const isValidPassword = this._hpHcpValidators.passwordStrength(password);
        if (!isValidPassword) {
            this.errors.newPasswordError = PASSWORD_STRENGTH_CRITERIA_ERROR;
        }
        return isValidPassword;
    }

    private _isConfirmedPasswordValid(password: string, confirmedPassword: string): boolean {
        const isConfirmedPasswordValid = password && password === confirmedPassword;
        if (!isConfirmedPasswordValid) {
            this.errors.confirmPasswordError = PASSWORDS_MATCH_ERROR;
        }
        return isConfirmedPasswordValid;
    }
}
