import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-dropdown',
    templateUrl: './dropdown.html',
    styleUrls: ['./dropdown.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropDownComponent {

    @Input() public isDisabled: boolean;
    @Input() public isHidden: boolean;
    @Input() public title: string;

    @Output() public onToggleDropDown: EventEmitter<any> = new EventEmitter();

    public toggleDropDown() {
        this.isHidden = !this.isHidden;
        this.onToggleDropDown.emit(this.isHidden);
    }

    public closeDropdownOnFocusOut() {
        this.isHidden = true;
        this.onToggleDropDown.emit(this.isHidden);
    }
}
