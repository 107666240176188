import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { HpHcpUrlRedirect } from '../../service/hp-hcp-url-redirect.service';
import { HpHcpNotificationsService, PRIORITY_SELECTORS, ICONS_NAMES } from '../../service/hp-hcp-notification.service';
import { DismissActionModel } from '../../model/dismiss-action.model';
import { SurgeryPhase } from '../../../shared/constants/surgery-phase';

@Component({
    standalone: false,
    selector: 'hp-hcp-notification',
    templateUrl: './notification.html',
    styleUrls: ['./notification.scss']
})
export class NotificationComponent implements OnChanges {

    @Input() public datetime: Date;
    @Input() public hasPatientDetails: boolean = true;
    @Input() public isDismissBoxVisible: boolean = false;
    @Input() public isDismissCommentRightSide: boolean = false;
    @Input() public message: string;
    @Input() public notificationId: number;
    @Input() public patientId: number;
    @Input() public patientSurgeryPhase: SurgeryPhase;
    @Input() public priority: string;
    @Input() public rule: string;

    @Output() public onDismissedNotification: EventEmitter<Object> = new EventEmitter();

    public prioritySelector: string;
    public iconName: string;

    private _notificationsService: HpHcpNotificationsService;
    private _urlRedirectService: HpHcpUrlRedirect;
    private _router: Router;

    constructor(hpHcpUrlRedirect: HpHcpUrlRedirect, notificationsService: HpHcpNotificationsService, router: Router) {
        this._urlRedirectService = hpHcpUrlRedirect;
        this._notificationsService = notificationsService;
        this._router = router;
    }

    public ngOnChanges() {
        this.prioritySelector = PRIORITY_SELECTORS[this.priority];
        this.iconName = this._getIcon(this.rule);
    }

    public toggleDismissCommentBox() {
        this.isDismissBoxVisible = !this.isDismissBoxVisible;
    }

    public dismissNotification(dismissAction: DismissActionModel) {
        const succesfulResponseDismissed = (isUpdated: boolean) => {
            if (isUpdated) {
                this.onDismissedNotification.emit(dismissAction);
            }
        };

        const succesfulResponseDisconnected = (isDisconnected: boolean) => {
            if (isDisconnected) {
                this.onDismissedNotification.emit(dismissAction);
            }
        };

        if (this.rule === 'urn:hws:app:disconnectedPatient') {
            this._notificationsService.updateNotification(this.patientId, dismissAction, succesfulResponseDismissed);
            this._notificationsService.disconnectNotification(this.patientId, dismissAction, succesfulResponseDisconnected);
        } else {
            this._notificationsService.updateNotification(this.patientId, dismissAction, succesfulResponseDismissed);
        }
    }

    public goToPatientProfile() {
        this._router.navigateByUrl(`${this._urlRedirectService.urls.patientProfile}/${this.patientId}`);
    }

    private _getIcon(notificationRule) {
        const actionListIcon = 'action-list-icon';
        const type = notificationRule.split(/[:]/).slice(-1);
        return ICONS_NAMES[type] || actionListIcon;
    }
}
