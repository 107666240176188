import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import cloneDeep from 'lodash/cloneDeep';

export interface MultiSelectModel {
  id: null | number;
  name: string;
}

@Component({
  standalone: false,
  selector: 'hp-hcp-multi-select',
  templateUrl: './multi-select.html',
  styleUrls: ['./multi-select.scss']
})
export class MultiSelectComponent implements OnChanges {
  @Input() public domId = 'option';
  @Input() public menuTitle: string;
  @Input() public options: Array<MultiSelectModel> = [];
  @Input() public selected: Array<string>;

  @Output() public onSubmit = new EventEmitter<Array<MultiSelectModel>>();

  public isChecked: Array<boolean>;
  public isOpen = false;
  public sortedOptions: Array<MultiSelectModel>;
  public title: string;

  public ngOnChanges() {
    this._initializeMenu();
  }

  public uncheckAll() {
    this.isChecked = this.isChecked.fill(false);
  }

  public submitAllSelection() {
    this.onSubmit.emit(this._getAllSelection());
  }

  public updateChecked() {
    this.isChecked = this.sortedOptions.map(({ id }) => (this.selected.includes(String(id))));
  }

  private _initializeMenu() {
    this._updateSortedOptions(cloneDeep(this.options));
    this.updateChecked();
  }

  private _getAllSelection(): Array<MultiSelectModel> {
    return this.sortedOptions.filter((_, index) => this.isChecked[index]);
  }

  private _updateSortedOptions(options) {
    this.sortedOptions = options.sort((a, b) => a.name.localeCompare(b.name));
  }
}
