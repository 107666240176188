import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

export const CLASS_NAME_ACTIVE = 'active';

@Directive({
  standalone: false,
  selector: '[toggleMenu]'
})
export class ToggleMenuDirective {
  public constructor(public el: ElementRef) { }

  @HostListener('click', ['$event.target']) onClick(selectedEl) {
    this.clearActive();
    selectedEl.classList.add(CLASS_NAME_ACTIVE);
  }

  private clearActive() {
    let menuOptions: any = this.el.nativeElement.querySelectorAll('li');
    Array.from(menuOptions).forEach(
      (_, idx) => menuOptions[idx].classList.remove(CLASS_NAME_ACTIVE)
    );
  }
}

@Directive({
  standalone: false,
  selector: '[toggleMenuActive]'
})
export class ToggleMenuActiveDirective implements OnInit {
  @Input() public toggleMenuActive = false;

  public constructor(public el: ElementRef) {}

  public ngOnInit() {
    if (this.toggleMenuActive) {
      this.el.nativeElement.classList.add(CLASS_NAME_ACTIVE);
    }
  }
}
