import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-pagination-controls',
    templateUrl: './pagination-controls.html',
    styleUrls: ['./pagination-controls.scss']
})
export class PaginationControlsComponent implements OnInit, OnChanges {
    @Input() public currentPage: number;
    @Input() public listLength: number;
    @Input() public pageSize: number;

    @Output() public onChangePage = new EventEmitter();

    public currentPageIndex: number;
    public firstItem: number;
    public isLeftDisabled: boolean;
    public isRightDisabled: boolean;
    public lastItem: number;
    public maxPage: number;
    public step: number;

    public ngOnInit() {
        this.step = 1;
    }

    public ngOnChanges() {
        this.updateIndicators();
        this.maxPage = this._getMaxPage();
        this.isLeftDisabled = this.currentPage === 1;
        this.isRightDisabled = this.currentPage === this.maxPage;
    }

    public navigateNext(pageDirection: number) {
        this.getCurrentPageNumber(pageDirection);
        this.onChangePage.emit(this.currentPage);
    }

    public getCurrentPageNumber(direction: number) {
        this.maxPage = this._getMaxPage();
        if (this.currentPage >= 1 && this.currentPage <= this.maxPage) {
            this.currentPage += direction;
        }
        if (this.currentPage < 1 || this.currentPage > this.maxPage) {
            this.currentPage -= direction;
        }
    }

    public updateIndicators() {
        this.currentPageIndex = this.currentPage - 1;
        this.firstItem = this.currentPageIndex * this.pageSize + 1;
        this.lastItem = this.currentPage * this.pageSize;
    }

    private _getMaxPage() {
        return (this.listLength === 0) ? 1 : Math.ceil(this.listLength / this.pageSize);
    }
}
