import { Component, Input } from '@angular/core';
export type ButtonType = 'button' | 'submit';

@Component({
  standalone: false,
  selector: 'hp-hcp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() public categoryId = 'button';
  @Input() public class = 'default';
  @Input() public disabled = false;
  @Input() public domId;
  @Input() public eventGroup: string;
  @Input() public type: ButtonType = 'button';
}

