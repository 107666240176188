import { Component, Input } from '@angular/core';
import { ActionListItemModel } from '../../../shared/model/action-list.model';

@Component({
    standalone: false,
    selector: 'hp-hcp-markable-list-item',
    templateUrl: './markable-list-item.component.html',
    styleUrls: ['./markable-list-item.component.scss']
})
export class MarkableListItemComponent {

    @Input() public item: ActionListItemModel;
    @Input() public patientId: number;

    constructor() {}

    public toggleCompleted() {
        if (this.item.locked) {
            return;
        }
    }

    public updateRefActionItem (completed) {
        this.item.completed = completed;
        this.item.markedAsCompleteBy = completed ? 'HCP' : '';
    }
}
