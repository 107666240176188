/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/*
 * @author      @version    @date            @description
 * PPareek      01           Dec 13, 2023    AFLL-19609 - Fixed Sonar Issues | Removed duplicate import.
 */  
@Component({
    standalone: false,
    selector: 'hp-hcp-warning-data-loss-modal',
    templateUrl: './warning-data-loss-modal.html',
    styleUrls: ['./warning-data-loss-modal.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WarningDataLossModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<WarningDataLossModalComponent>
    ) {
        dialogRef.disableClose = true;
    }

    public closeModal() {
        this.dialogRef.close(false);
    }

    public closeAndNavigateAway() {
        this.dialogRef.close(true);
    }

}
