import { Component, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FooterWarningDataLossModalComponent } from './warning-data-loss-modal/warning-data-loss-modal.component';
import { HpHcpLocalStorage } from '../../service/hp-hcp-localstorage.service';

import { Router } from '@angular/router';
import { HpHcpUrlRedirect } from '../../service/hp-hcp-url-redirect.service';
import { environment } from '../../../../environments/environment';
import { UserModel } from '../../model/user.model';
import { HpHcpUserSession } from '../../service/hp-hcp-user-session.service';
import { AppConstants } from '../../../config/constants';

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Apr 01, 2023    AFLL-16880 - Supporting Angular CLI build and refactoring
 */
@Component({
    standalone: false,
    selector: 'tkr-footer',
    templateUrl: './footer.html',
    styleUrls: ['./footer.scss']
})

export class FooterComponent implements OnInit {

    @Input() public isAuthenticated = true;
    @Input() public displayWarningDataLossModal = false;

    @Output() public footerLinkClickedEvent = new EventEmitter<boolean>();


    public year = new Date().getFullYear();
    public isSso = false;
    public userSession: UserModel;
    public country: string;

    constructor(
        private router: Router,
        public urlRedirectService: HpHcpUrlRedirect,
        private localStorage: HpHcpLocalStorage,
        public dialog: MatDialog,
        private userSessionService: HpHcpUserSession
    ) {}

    public ngOnInit() {
        this.userSession = this.userSessionService.getUserSession();
        this.setCountry();
        this.addCookieScript();
        this.isSso = this.router.url.startsWith(this.urlRedirectService.urls.sso);
    }

    public setCountry() {
        if (this.userSession) {
            this.country = this.userSession.organization.country ? this.userSession.organization.country : environment.country;
            return;
        }
        this.country = environment.country;
    }

    public goToPrivacyPolicyScreen() {
        if (this.displayWarningDataLossModal) {
            this.dialog.open(FooterWarningDataLossModalComponent, {
                panelClass: 'warning-data-loss-modal',
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.localStorage.removeLocalStorageItem('isRegistrationInProgress');
                    this.footerLinkClickedEvent.emit(true);
                    this.router.navigateByUrl(this.urlRedirectService.urls.privacyPolicy[this.country.toLowerCase()]);
                }
            });
        } else {
            this.footerLinkClickedEvent.emit(true);
            this.router.navigateByUrl(this.urlRedirectService.urls.privacyPolicy[this.country.toLowerCase()]);
        }
    }

    public goToCookiePolicyScreen() {
        if (this.displayWarningDataLossModal) {
            this.dialog.open(FooterWarningDataLossModalComponent, {
                panelClass: 'warning-data-loss-modal',
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.localStorage.removeLocalStorageItem('isRegistrationInProgress');
                    this.footerLinkClickedEvent.emit(true);
                    this.router.navigateByUrl(this.urlRedirectService.urls.CookiePolicies);
                }
            });
        } else {
            this.footerLinkClickedEvent.emit(true);
            this.router.navigateByUrl(this.urlRedirectService.urls.CookiePolicies);
        }
    }

    public goToContactUsScreen() {
        if (this.displayWarningDataLossModal) {
            this.dialog.open(FooterWarningDataLossModalComponent, {
                panelClass: 'warning-data-loss-modal',
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.localStorage.removeLocalStorageItem('isRegistrationInProgress');
                    this.footerLinkClickedEvent.emit(true);
                    this.router.navigateByUrl(this.urlRedirectService.urls.ContactUs);
                }
            });
        } else {
            this.footerLinkClickedEvent.emit(true);
            this.router.navigateByUrl(this.urlRedirectService.urls.ContactUs);
        }
    }

    public addCookieScript() {
        if (this.userSession && this.country && !document.getElementById('cookie-script')) {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = AppConstants.cookieDataDomainUrl;
            script.setAttribute('data-document-language', 'true');
            script.setAttribute('data-domain-script', AppConstants.cookieDomainScriptId);
            script.setAttribute('id', 'cookie-script');
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    }

    public goToCookieSettings() {
        (window as any).OneTrust.ToggleInfoDisplay();
    }

    @HostListener('document:gaAccepted')
    public startGaSession() {
        if (this.userSession) {
            this.userSessionService.startGaSession(this.userSession);
        }
    }
}
