import { Component, Input } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-patient-empty-state-column',
    templateUrl: './empty-state-column.html',
    styleUrls: ['./empty-state-column.scss']
})
export class EmptyStateColumnComponent {

    @Input() public title: string;
    @Input() public disconnectedDate: string;

}
