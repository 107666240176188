import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';

// Had to use require since the typescript wasn't compiling due to typings issues
const { Circle } = require('progressbar.js');

@Component({
    standalone: false,
    selector: 'hp-hcp-progressbar-circular',
    templateUrl: './progressbar-circular.html',
    styleUrls: ['./progressbar-circular.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgressbarCircularComponent implements AfterViewInit, OnChanges, OnInit {

    @Input() public denominator: number;
    @Input() public displayPercentage?: boolean = false;
    @Input() public hasEmptyState: boolean;
    @Input() public id: number;
    @Input() public idPrefix: string;
    @Input() public numerator: number;
    @Input() public isCritical: boolean = false;
    @Input() public type: 'regular'|'small'|'four-day' = 'regular';
    @Input() public title?: string = '';

    @ViewChild('progressBar', { read: ViewContainerRef})
    public progressBarRef: ViewContainerRef;

    public percentage: number = 0;

    private _progressBar;

    public ngOnInit() {
        this.percentage = this._calculatePercentage();
    }

    public ngOnChanges() {
        this.percentage = this._calculatePercentage();

        if (this._progressBar) {
            this._progressBar.destroy();
            this._progressBar = null;
            this._instantiateProgressBar();
        }
    }

    public ngAfterViewInit() {
        this._instantiateProgressBar();
    }

    public shouldDisplayEmptyState() {
        return !this.denominator && this.hasEmptyState;
    }

    private _calculatePercentage() {
        return this.denominator
            ? this.numerator / this.denominator
            : 0;
    }

    private _instantiateProgressBar() {
        this._progressBar = new Circle(this.progressBarRef.element.nativeElement, {
            easing: 'easeInOut',
            strokeWidth: 6,
            trailWidth: 6
        });

        this._progressBar.animate(this.percentage);
    }

}
