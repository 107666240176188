import { Component, Input } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-patient-surgery-item',
    templateUrl: './patient-surgery-item.html',
    styleUrls: ['./patient-surgery-item.scss']
})
export class PatientSurgeryItemComponent {

    @Input() public completed: boolean;
    @Input() public date: string;
    @Input() public occurrenceDate?: string;
    @Input() public patientAdded?: boolean = false;
    @Input() public isOverdue?: boolean = false;
    @Input() public isRecurring?: boolean = false;
    @Input() public title: string;
    @Input() public type?: string = '';

}
