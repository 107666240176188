import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { DismissActionModel } from '../../../model/dismiss-action.model';
import { HpHcpNotificationsService } from '../../../service/hp-hcp-notification.service';
import { SurgeryPhase } from '../../../../shared/constants/surgery-phase';

const DISMISS_WITH_COMMENT_CODE = 'DISMISS_WITH_COMMENT';

@Component({
    standalone: false,
    selector: 'hp-hcp-dismiss-notification',
    templateUrl: './dismiss-notification.html',
    styleUrls: ['./dismiss-notification.scss']
})
export class DismissNotificationComponent implements OnInit {

    @Input() public notificationType: string;
    @Input() public notificationId: string;
    @Input() public patientId: number;
    @Input() public patientSurgeryPhase: SurgeryPhase;

    @Output() public onDismissNotification: EventEmitter<Object> = new EventEmitter();
    @Output() public onShowDismissComment: EventEmitter<Object> = new EventEmitter();

    public actionsList: Array<DismissActionModel>;
    public isListVisible: boolean = false;

    private _hpHcpNotificationsService: HpHcpNotificationsService;
    private _window: any;

    constructor(hpHcpNotificationsService: HpHcpNotificationsService) {
        this._hpHcpNotificationsService = hpHcpNotificationsService;
        this._window = window;
    }

    public ngOnInit() {
        this._hpHcpNotificationsService.getNotificationActions(response => this.actionsList = response);
    }

    public closeListOnFocusOut() {
        if ( this.isListVisible ) {
            this.triggerNotificationCloseGA();
        }
        this.isListVisible = false;

    }

    public dismissNotification(code: string) {
        this.toggleDismissList();

        if (code === DISMISS_WITH_COMMENT_CODE) {
            this.onShowDismissComment.emit();
            return;
        }

        const notificationAction = {
            notificationId: this.notificationId,
            action: code
        };
        this.onDismissNotification.emit(notificationAction);
    }

    public toggleDismissList() {
        this.isListVisible = !this.isListVisible;
    }

    private triggerNotificationCloseGA() {
        this._window.dataLayer.push({
            'event': `gtm.clickoutside`,
            'gtm.element.dataset.domId': `notification_action_menu_close`
        });
    }
}
