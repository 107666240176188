import { Component, Input, OnChanges } from '@angular/core';

import { PRIORITY_SELECTORS, PRIORITY_BASE } from  '../../service/hp-hcp-notification.service';

const NUMBER_OF_BARS = 3;

@Component({
    standalone: false,
    selector: 'hp-hcp-urgency-bars',
    templateUrl: './urgency-bars.html',
    styleUrls: ['./urgency-bars.scss']
})
export class UrgencyBarsComponent implements OnChanges {
    public barsClassNames: Array<string>;

    @Input() public priority: number;

    public ngOnChanges() {
        this.priority = this.priority || 0;
        this.barsClassNames = this.getBarsClassNames();
    }

    public getBarsClassNames() {
        const priorityIndex = this.priority / PRIORITY_BASE;
        const baseClassNameList: Array<string> = new Array(NUMBER_OF_BARS);
        const barClassName = this._getBarClassName();
        return baseClassNameList.fill(barClassName, 0, priorityIndex);
    }

    private _getBarClassName() {
        return PRIORITY_SELECTORS[this.priority];
    }

}
