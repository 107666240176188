import {
    AfterViewChecked,
    Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

@Directive({
    standalone: false,
    selector: '[adjustSourceHeightToTarget]'
})
export class AdjustSourceHeightToTargetDirective implements AfterViewChecked {

    @Input() public adjustSourceHeightToTarget: string;

    constructor(private _elementRef: ElementRef) { }

    public ngAfterViewChecked() {
        this.adjustHeightToTarget(this.adjustSourceHeightToTarget);
    }

    @HostListener('window:resize')
    public onResize() {
        this.adjustHeightToTarget(this.adjustSourceHeightToTarget);
    }

    public adjustHeightToTarget(selector: string) {

        const targetElement = window.document.querySelector(selector);

        if (!targetElement) {
             return;
        }
        const sourceElement = this._elementRef.nativeElement;
        sourceElement.style.cssText = '';
        const initialHeight = parseInt(window.getComputedStyle(sourceElement, null).getPropertyValue('height'), 10);
        const maxHeight = Math.max(targetElement.getBoundingClientRect().height, initialHeight);
        sourceElement.style.height = `${maxHeight}px`;
    }
}
