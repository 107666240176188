import { Component } from '@angular/core';

import { HpHcpHttp } from '../../service/hp-hcp-http.service';

@Component({
    standalone: false,
    selector: 'tkr-progress-spinner',
    templateUrl: './progress-spinner.html',
    styleUrls: ['./progress-spinner.scss']
})
export class ProgressSpinnerComponent {

    public httpService;

    public constructor(httpService: HpHcpHttp) {
        this.httpService = httpService;
    }

}
