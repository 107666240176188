import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    standalone: false,
    selector: 'hp-hcp-warning-data-loss-modal',
    templateUrl: './warning-data-loss-modal.html',
    styleUrls: ['./warning-data-loss-modal.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FooterWarningDataLossModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<FooterWarningDataLossModalComponent>
    ) {
        dialogRef.disableClose = true;
    }

    public closeModal() {
        this.dialogRef.close(false);
    }

    public closeAndNavigateAway() {
        this.dialogRef.close(true);
    }

}
