import {
    AfterViewChecked,
    Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

@Directive({
    standalone: false,
    selector: '[scrollElementIntoView]'
})
export class ScrollElementIntoViewDirective implements AfterViewChecked {

    @Input() public scrollElementIntoView: string;

    constructor(private _elementRef: ElementRef) { }

    public ngAfterViewChecked() {
        this.adjustScrollTop(this.scrollElementIntoView);
    }

    @HostListener('window:resize')
    public onResize() {
        this.adjustScrollTop(this.scrollElementIntoView);
    }

    public adjustScrollTop(selector: string) {

        const containerElement = this._elementRef.nativeElement;
        const childElement = containerElement.querySelector(selector);

        if (!childElement) {
             return;
        }

        if (!this.checkIfElementInView(containerElement, childElement)) {
            containerElement.scrollTop = childElement.offsetTop - containerElement.offsetTop;
        }

    }

    private checkIfElementInView(containerElement, childElement) {
        const containerTop = containerElement.scrollTop;
        const containerBottom = containerTop + containerElement.clientHeight;
        const elementTop = childElement.offsetTop;
        const elementBottom = elementTop + childElement.clientHeight;
        const isInView = (elementTop < containerTop && elementBottom > containerTop) ||
                         (elementBottom > containerBottom && elementTop < containerBottom);
        return isInView;
    }

}
