import { Component, Input } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-patient-photo',
    templateUrl: './patient-photo.html',
    styleUrls: ['./patient-photo.scss']
})
export class PatientPhotoComponent {

    @Input() public photoUrl: string;
    @Input() public firstName: string;
    @Input() public lastName: string;
    @Input() public patientId: number;
    @Input() public noEdit: boolean;
    
    public getPatientInitials() {
        return (this.firstName.charAt(0) + this.lastName.charAt(0)).toUpperCase();
    }

}
