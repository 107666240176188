import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HpHcpAuth } from '../../shared/service/hp-hcp-auth.service';
import { HpHcpUrlRedirect } from '../../shared/service/hp-hcp-url-redirect.service';
import { HpHcpUserSession } from '../../shared/service/hp-hcp-user-session.service';
import { UserModel } from '../../shared/model/user.model';
import { HpHcpValidators } from '../../shared/service/hp-hcp-validators.service';
import { AppConstants } from '../../config/constants';

const EXPIRED_PASSWORD_STATUS_CODE = 412;

@Component({
    standalone: false,
    selector: 'hp-hcp-auth-app',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})

export class LoginComponent implements OnInit {
    public isInactiveLogout = false;
    public isSessionValid = true;
    public loginForm: FormGroup;
    public isSubmitted = false;
    public isShowContent = false;
    private _postLoginRoute = '';

    constructor(
        private _authService: HpHcpAuth,
        private _formBuilder: FormBuilder,
        private _routeParams: ActivatedRoute,
        private _router: Router,
        private _toastr: ToastrService,
        private _urlRedirectService: HpHcpUrlRedirect,
        private _userSessionService: HpHcpUserSession,
        hpHcpValidators: HpHcpValidators
    ) {
        this.loginForm = this._formBuilder.group({
            'email': ['', [hpHcpValidators.email, Validators.required]],
            'password': ['']
        });
        if (!this._userSessionService.getUserSession()) {
            window.location.href = `${AppConstants.vdsUrl}/#/login`;
        } else {
            this.isShowContent = true;
        }
    }

    public ngOnInit() {

        this._routeParams.queryParams.subscribe((params: Params) => {
            let inactivity = params['inactivity'];
            if (!!inactivity) {
                this.isInactiveLogout = true;
                this._prePopulateEmail();
            }
            if (!!params['sessionInterrupted']) {
                this.isSessionValid = false;
            }
            let route = params['route'];
            if (route) {
                this._postLoginRoute = route;
            }

            if (this._userSessionService.getUserSession()) {
                this._successfulLogin();
                this.isShowContent = true;
            } else {
                window.location.href = `${AppConstants.vdsUrl}/#/login`;
            }
        });
    }

    public login() {

        this.isSubmitted = true;

        this._authService.login(
            this.loginForm.value,
            this._successfulLogin.bind(this),
            this._failedLogin.bind(this)
        );
    }

    private _failedLogin({message, code}) {
        if (code === EXPIRED_PASSWORD_STATUS_CODE) {
            const expiredPassword = true;
            this._router.navigate(['forgotpassword'], {queryParams: {expiredPassword}});
        }
        this._toastr.error(message, 'Error');
        this.isSubmitted = false;

    }

    private _prePopulateEmail() {
        const lastEmail = this._userSessionService.getUserEmail() || '';
        this.loginForm.get('email').patchValue(lastEmail);
    }

    private _navigateWithPageviewToRequestedPage(userData) {
        this._router.navigateByUrl(this._postLoginRoute);
    }

    private _navigatewithPageviewToMainPage(userData) {
        this._router.navigate([this._urlRedirectService.urls.dashboard]);
    }

    private _navigateToOrganizationsPage() {
        const redirect: string = this._postLoginRoute;
        this._router
            .navigate([
                this._urlRedirectService.urls.organizations,
                {redirect}
            ]);
    }

    private _successfulLogin(userData: UserModel = null) {
        const orgSelectionRequired = this._authService.orgSelectionPermitted();
        this.isSubmitted = false;
        orgSelectionRequired
            ? this._navigateToOrganizationsPage()
            : this._postLoginRoute
            ? this._navigateWithPageviewToRequestedPage(userData)
            : this._navigatewithPageviewToMainPage(userData);
    }

    get isInValidEmail() {
        const { errors }: any = this.loginForm.get('email');
        return (errors != undefined && errors.badFormat);
    }
}
