import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, Input, forwardRef } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxComponent),
        multi: true
    }]
})
export class CheckboxComponent implements ControlValueAccessor {

    public _checked: boolean;
    public _id = Math.ceil(Math.random() * 1000000).toString();

    @Input('checked') set checked (conditional: boolean) {
        this._checked = conditional === true ? true : false;
    }
    @Input() public class: string;
    @Input() public disabled = false;
    @Input() public domId;
    @Input() public eventGroup: string;
    @Input() public name = `chk-${this._id}`;

    public onChange(event: any) {
        const value = event.target.checked;
        this.checked = value;
        this.propagateChange(value);
    }

    public writeValue(value: any) {
        this.checked = value;
    }
    public propagateChange = (_: any) => null;
    public registerOnChange(fn: any) {
            this.propagateChange = fn;
    }
    public setDisabledState (isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    public registerOnTouched(fn: any) {
        //
    }
}
