// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Apr 05, 2023    AFLL-16880 - Supporting Angular CLI build + Angular 15 & refactoring
 *  PPareek     02          Sep 05, 2024    AFLL-21637 - Fixed Sonar Issues
 */
.title {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #26282a;
}

.button {
  min-width: 200px;
  min-height: 50px;
  border-radius: 22px;
  border: 2px solid #2065c6;
  background-color: #fff;
  color: #2065c6;
  font-weight: 800;
  margin: 2%;
  outline: none;
}

.message {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #404d66;
  margin: 4rem;
  text-align: left;
}

::ng-deep .mat-dialog-container,
::ng-deep .mdc-dialog__surface {
  box-shadow: none;
  background: transparent;
}

.expired-subscription-modal-body {
  text-align: center;
  border-radius: 20px;
  width: 700px;
  box-shadow: 0 11px 26px -10px rgba(234, 235, 238, 0.54);
  background: #fff;
  padding: 24px;
}
.expired-subscription-modal-body .form-buttons {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/ui/header/subscription-expired-modal/subscription-expired-modal.scss"],"names":[],"mappings":"AACA;;;;EAAA;AAMA;EACE,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,UAAA;EACA,aAAA;AADF;;AAIA;EACE,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;;EAEE,gBAAA;EACA,uBAAA;AADF;;AAIA;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,uDAAA;EACA,gBAAA;EACA,aAAA;AADF;AAEE;EACE,iBAAA;AAAJ","sourcesContent":["\n/*\n *  @author     @version    @date           @description\n *  HSenevir    01          Apr 05, 2023    AFLL-16880 - Supporting Angular CLI build + Angular 15 & refactoring\n *  PPareek     02          Sep 05, 2024    AFLL-21637 - Fixed Sonar Issues\n */\n\n.title {\n  font-family: 'Roboto', sans-serif;\n  font-size: 30px;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: 0.35px;\n  color: #26282a;\n}\n\n.button {\n  min-width: 200px;\n  min-height: 50px;\n  border-radius: 22px;\n  border: 2px solid #2065c6;\n  background-color: #fff;\n  color: #2065c6;\n  font-weight: 800;\n  margin: 2%;\n  outline: none;\n}\n\n.message {\n  font-family: 'Roboto', sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-stretch: normal;\n  font-weight: normal;\n  line-height: normal;\n  letter-spacing: 0.35px;\n  color: #404d66;\n  margin: 4rem;\n  text-align: left;\n}\n\n::ng-deep .mat-dialog-container,\n::ng-deep .mdc-dialog__surface {\n  box-shadow: none;\n  background: transparent;\n}\n\n.expired-subscription-modal-body {\n  text-align: center;\n  border-radius: 20px;\n  width: 700px;\n  box-shadow: 0 11px 26px -10px rgba(234, 235, 238, 0.54);\n  background: #fff;\n  padding: 24px;\n  .form-buttons {\n    text-align: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
