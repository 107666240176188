import { Directive, HostListener, Input } from '@angular/core';

import { HpHcpBctEventService } from '../../shared/service/hp-hcp-bct-event.service';

@Directive({
    standalone: false,
    selector: '[bctEventCapture]'
})
export class BctEventCaptureDirective {

    @Input() public bctEventCapture: boolean;
    @Input() public bctEventCode: string;
    @Input() public bctPatientId: number;
    @Input() public bctEventSection: string;

    private _bcpEventService: HpHcpBctEventService;

    public constructor(bcpEventService: HpHcpBctEventService) {
        this._bcpEventService = bcpEventService;
    }

    @HostListener('click') public onEvent() {
        if (this.bctEventCapture) {
            this._bcpEventService.saveEvent(this.bctEventCode, this.bctEventSection, this.bctPatientId);
        }
    }

}
