import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    standalone: false,
    selector: 'hp-hcp-slider',
    templateUrl: './slider.html',
    styleUrls: ['./slider.scss']
})
export class SliderComponent {

    @Input() public initial = 0;
    @Input() public max = 100;
    @Input() public min = 0;

    @Output() public onValueChange = new EventEmitter();

    public currentValue = 0;

    public ngOnInit() {
        this.currentValue = this.initial;
    }

}
